import firebase from "firebase/compat/app";

import "firebase/compat/firestore";
import initFirebase from "./initFirebase";

initFirebase();

const initFirestore = () => {
  return firebase.firestore();
};

export default initFirestore;
