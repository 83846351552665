import { useEffect, useState, useContext, Dispatch, SetStateAction, createContext } from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { UserData } from "types";

import initFirebase from "./initFirebase";
import initFirestore from "./initFirestore";
import { mapUserData } from "./mapUserData";
import { removeUserCookie, setUserCookie, getUserFromCookie } from "./userCookies";

type User = UserData & { id: string; displayName: string; email: string; token: string; photoURL: string };

initFirebase();
initFirestore();

const db = firebase.firestore();

const logout = async (callback?: Function) => {
  return firebase
    .auth()
    .signOut()
    .then(() => {
      // Sign-out successful.
      if (callback) {
        callback();
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

const AuthContext = createContext<{
  user?: Partial<User> | null;
  userIsLoading: boolean;
  logout: typeof logout;
  userAdditionalData?: Partial<User> | null;
  userAdditionalDataIsLoading: boolean;
  showLogin: boolean;
  setShowLogin: Dispatch<SetStateAction<boolean>>;
  updateUserAdditionalData: Function;
}>({
  user: undefined,
  userIsLoading: true,
  userAdditionalData: undefined,
  userAdditionalDataIsLoading: false,
  logout,
  showLogin: false,
  setShowLogin: () => {},
  updateUserAdditionalData: () => {},
});

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, userIsLoading, userAdditionalData, userAdditionalDataIsLoading, logout, updateUserAdditionalData } =
    useAuth();

  const [showLogin, setShowLogin] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        user,
        userIsLoading,
        userAdditionalData,
        userAdditionalDataIsLoading,
        logout,
        showLogin,
        setShowLogin,
        updateUserAdditionalData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const {
    user,
    userIsLoading,
    logout,
    showLogin,
    setShowLogin,
    userAdditionalData,
    userAdditionalDataIsLoading,
    updateUserAdditionalData,
  } = useContext(AuthContext);

  return {
    user,
    userIsLoading,
    logout,
    showLogin,
    setShowLogin,
    userAdditionalData,
    userAdditionalDataIsLoading,
    updateUserAdditionalData,
  };
};

const useAuth = () => {
  const [user, setUser] = useState<Partial<User> | null>();
  const [userIsLoading, setUserIsLoading] = useState<boolean>(true);
  const [userAdditionalData, setUserAdditionalData] = useState<Partial<User> | null>();
  const [userAdditionalDataIsLoading, setUserAdditionalDataIsLoading] = useState<boolean>(false);

  const updateUserAdditionalData = (userData?: Partial<User>) => {
    if (!userData) {
      setUserAdditionalData(null);
      setUserAdditionalDataIsLoading(false);
      return;
    }

    setUserAdditionalDataIsLoading(true);
    const doc = db.collection("users").doc(userData?.id || user?.id);

    const updateLocalUserData = (doc) => {
      setUserAdditionalData({ ...doc.data() });
      setUserAdditionalDataIsLoading(false);
    };

    doc.onSnapshot(updateLocalUserData);
  };

  useEffect(() => {
    // Firebase updates the id token every hour, this
    // makes sure the react state and the cookie are
    // both kept up to date
    const cancelAuthListener = firebase.auth().onIdTokenChanged((user) => {
      if (user) {
        const userData = mapUserData(user);
        setUserCookie(userData);
        setUser(userData);

        updateUserAdditionalData(userData);
      } else {
        removeUserCookie();
        setUser(null);
        updateUserAdditionalData();
      }

      setUserIsLoading(false);
    });

    const userFromCookie = getUserFromCookie();

    return () => {
      cancelAuthListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { user, userIsLoading, userAdditionalData, userAdditionalDataIsLoading, logout, updateUserAdditionalData };
};

export { useAuth, AuthContextProvider, useAuthContext };
